<template>
  <div>
    <v-row v-if="isMobile">
      <v-col
        cols="12">
        <div
          ref="stock-table"
          style="height: 600px !important; overflow: scroll;">
          <div class="text-center">
            <v-simple-table
              v-for="(item, index) in noteData"
              :key="`stock-table-body-row${index}`"
              width="100%"
              class="elevation-4 my-4 mx-2 pa-2">
              <tbody>
                <tr>
                  <td
                    width="100px"
                    class="text-left">
                    Code.
                  </td>
                  <td class="text-left">
                    <span class="text-bold text-h6">{{ item.code }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    width="100px"
                    class="text-left">
                    วันที่สร้าง
                  </td>
                  <td class="text-left">
                    {{ $dayjs(item.createdAt).format('DD MMM YYYY | HH:mm:ss') }}
                  </td>
                </tr>
                <tr>
                  <td
                    width="100px"
                    class="text-left">
                    วันที่อัพเดต
                  </td>
                  <td class="text-left">
                    {{ $dayjs(item.updatedAt).format('DD MMM YYYY | HH:mm:ss') }}
                  </td>
                </tr>
                <tr>
                  <td
                    width="100px"
                    class="text-left">
                    Note
                  </td>
                  <td
                    class="note-column text-left">
                    {{ noteCompute(item) }}
                  </td>
                </tr>
                <tr>
                  <td
                    width="100px"
                    class="text-left">
                    Warehouse
                  </td>
                  <td
                    class="note-column text-left">
                    <div
                      v-if="refBy('deliveryNote', item)"
                      class="transfer-chip">
                      <v-chip
                        :class="`warehouse-color-${getLastId(mapNowWarehouseProp(item, 'id'))}`"
                        x-small>
                        {{ mapNowWarehouseProp(item, 'name') || '' }}
                      </v-chip>
                      <v-icon x-small>
                        mdi-arrow-right-bold
                      </v-icon>
                      <v-chip
                        :class="`warehouse-color-${getLastId(mapOtherWarehouseProp(item, 'id'))}`"
                        x-small>
                        {{ mapOtherWarehouseProp(item, 'name') || '' }}
                      </v-chip>
                    </div>

                    <div
                      v-else-if="refBy('withdrawNote', item)"
                      class="transfer-chip">
                      <v-chip
                        :class="`warehouse-color-${getLastId(mapOtherWarehouseProp(item, 'id'))}`"
                        x-small>
                        {{ mapOtherWarehouseProp(item, 'name') || '' }}
                      </v-chip>
                      <v-icon x-small>
                        mdi-arrow-right-bold
                      </v-icon>
                      <v-chip
                        :class="`warehouse-color-${getLastId(mapNowWarehouseProp(item, 'id'))}`"
                        x-small>
                        {{ mapNowWarehouseProp(item, 'name') || '' }}
                      </v-chip>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="100px">
                  </td>
                  <td class="text-right">
                    <v-btn
                      v-if="isCancel"
                      class="mx-1 mt-2"
                      color="error"
                      elevation="0"
                      :loading="item.loading"
                      tile
                      small
                      :disabled="isDisabledByRole"
                      @click="cancel(index)">
                      Cancel
                    </v-btn>
                    <v-btn
                      :to="{
                        name: to,
                        query: {
                          id: item.id,
                          warehouse: warehouseId
                        }
                      }"
                      target="_blank"
                      class="mx-1 mt-2"
                      color="grey lighten-2"
                      elevation="0"
                      tile
                      small>
                      Detail
                    </v-btn>
                    <v-btn
                      v-if="isShowSeparate"
                      :to="{
                        name: 'MoveProducts',
                        query: {
                          noteId: item.id,
                          warehouseId: warehouseId
                        }
                      }"
                      target="_blank"
                      class="mx-1  mt-2"
                      color="primary"
                      elevation="0"
                      tile
                      small>
                      ย้ายสินค้า
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-icon
              v-if="loading"
              class="mdi mdi-loading spin"
              color="black">
            </v-icon>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-simple-table
      v-else
      ref="stock-table"
      fixed-header
      :height="500">
      <thead>
        <tr>
          <th
            v-for="(head, index) in tableHead"
            :key="`stock-table-head-${index}`"
            :class="head.align"
            :width="head.width">
            {{ head.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-if="loading"
          class="simple-table-loading">
          <td
            :colspan="tableHead.length">
            <v-progress-linear
              color="primary"
              indeterminate />
          </td>
        </tr>
        <tr
          v-if="noteData.length === 0"
          class="text-center">
          <td colspan="5">
            ไม่พบรายการ...
          </td>
        </tr>
        <tr
          v-for="(item, index) in noteData"
          :key="`stock-table-body-${index}`">
          <td>
            <span class="text-bold">{{ item.code }}</span> <br>
            <span>{{ item.purchaseOrderId }}</span>
          </td>
          <td>
            {{ $dayjs(item.createdAt).format('DD MMM YYYY | HH:mm:ss') }}
          </td>
          <td>
            {{ $dayjs(item.updatedAt).format('DD MMM YYYY | HH:mm:ss') }}
          </td>
          <td class="note-column">
            {{ noteCompute(item) }}
          </td>
          <td>
            <v-btn
              v-if="item.autoDeliveryNoteId"
              :to="{
                name: 'MoveProducts',
                query: {
                  noteCode: item.code,
                  autoDeliveryNoteId: item.autoDeliveryNoteId
                }
              }"
              target="_blank"
              class="mx-1"
              elevation="0"
              tile
              text
              small>
              รายละเอียด
            </v-btn>
          </td>
          <td>
            <div class="d-flex justify-end align-center">
              <div
                v-if="refBy('deliveryNote', item)"
                class="transfer-chip">
                <v-chip
                  :class="`warehouse-color-${getLastId(mapNowWarehouseProp(item, 'id'))}`"
                  x-small>
                  {{ mapNowWarehouseProp(item, 'name') || '' }}
                </v-chip>
                <v-icon x-small>
                  mdi-arrow-right-bold
                </v-icon>
                <v-chip
                  :class="`warehouse-color-${getLastId(mapOtherWarehouseProp(item, 'id'))}`"
                  x-small>
                  {{ mapOtherWarehouseProp(item, 'name') || '' }}
                </v-chip>
              </div>
              <div
                v-else-if="refBy('withdrawNote', item)"
                class="transfer-chip">
                <v-chip
                  :class="`warehouse-color-${getLastId(mapOtherWarehouseProp(item, 'id'))}`"
                  x-small>
                  {{ mapOtherWarehouseProp(item, 'name') || '' }}
                </v-chip>
                <v-icon x-small>
                  mdi-arrow-right-bold
                </v-icon>
                <v-chip
                  :class="`warehouse-color-${getLastId(mapNowWarehouseProp(item, 'id'))}`"
                  x-small>
                  {{ mapNowWarehouseProp(item, 'name') || '' }}
                </v-chip>
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex justify-end align-center">
              <v-btn
                v-if="isCancel"
                class="mx-1"
                color="error"
                elevation="0"
                :loading="item.loading"
                tile
                small
                :disabled="isDisabledByRole"
                @click="cancel(index)">
                Cancel
              </v-btn>

              <v-btn
                :to="{
                  name: to,
                  query: {
                    id: item.id,
                    warehouse: warehouseId
                  }
                }"
                target="_blank"
                class="mx-1"
                color="grey lighten-2"
                elevation="0"
                tile
                small>
                Detail
              </v-btn>
              <v-btn
                v-if="isShowSeparate"
                :to="{
                  name: 'MoveProducts',
                  query: {
                    noteId: item.id,
                    warehouseId: warehouseId
                  }
                }"
                target="_blank"
                class="mx-1"
                color="primary"
                elevation="0"
                tile
                small>
                ย้ายสินค้า
              </v-btn>
            </div>
          </td>
        </tr>
        <tr
          v-if="loading && noteData.length > 0"
          class="simple-table-loading">
          <td
            :colspan="tableHead.length">
            <v-progress-linear
              color="primary"
              indeterminate />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import { getRole } from '@/assets/js/Authentication'

export default {
  props: {
    height: {
      type: [Number, String],
      default: ''
    },
    noteData: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    warehouseId: {
      type: Number,
      default: 0
    },
    warehouse: {
      type: Array,
      default: () => []
    },
    isMobile: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      tableHead: [
        {
          label: 'Code No.',
          align: 'text-left',
          width: 150
        },
        {
          label: 'Create Date',
          align: 'text-left',
          width: 180
        },
        {
          label: 'Update Date',
          align: 'text-left',
          width: 180
        },
        {
          label: 'Note',
          align: 'text-left'
        },
        {
          label: 'Auto Delivery Note',
          align: 'text-left',
          width: 150
        },
        {
          label: 'Warehouse',
          align: 'text-left',
          width: 150
        },
        {
          label: '',
          align: 'text-right'
        }
      ],
      noteSelected: null
    }
  },
  computed: {
    isShowSeparate () {
      return this.status === 'approved' && this.warehouseId !== 0
    },
    isCancel () {
      return this.status === 'pending' || this.status === 'draft'
    },
    isRenew () {
      return this.status === 'canceled'
    },
    isDisabledByRole () {
      const dnRole = [
        'management',
        'area_manager',
        'marketing',
        'developer',
        'creative',
        'merchandising_planner',
        'merchandiser',
        'online_admin',
        'accounting_manager',
        'warehouse_manager',
        'warehouse',
        'store_manager',
        'acting_assist_store_manager',
        'assist_store_manager',
        'sales_staff'
      ]
      const role = getRole()
      return !dnRole.some((r) => r === role)
    }
  },
  mounted () {
    if (this.$refs['stock-table']) {
      if (this.isMobile) {
        this.$refs['stock-table'].addEventListener('scroll', this.handleScroll)
      } else {
        this.$refs['stock-table'].$el.firstElementChild.addEventListener('scroll', this.handleScroll)
      }
    }
  },
  beforeDestroy () {
    if (this.$refs['stock-table']) {
      if (this.isMobile) {
        this.$refs['stock-table'].removeEventListener('scroll', this.handleScroll)
      } else {
        this.$refs['stock-table'].$el.firstElementChild.removeEventListener('scroll', this.handleScroll)
      }
    }
  },
  methods: {
    getSelectedNoteId () {
      return this.noteSelected?.id || null
    },
    getSelectedNoteWarehouseID () {
      return this.noteSelected?.warehouse?.id || null
    },
    isRef (item) {
      return (item.withdrawNoteRef && item.withdrawNoteRef?.id) || (item.deliveryNoteRef && item.deliveryNoteRef?.id)
    },
    refBy (target, item) {
      let result = false
      if (target === 'withdrawNote') {
        result = this.type === 'DeliveryNote' && !!item.withdrawNoteRef
      } else if (target === 'deliveryNote') {
        result = this.type === 'WithdrawNote' && !!item.deliveryNoteRef
      }

      return result
    },
    noteCompute (item) {
      let refs = ''
      if (item.withdrawNoteRef?.code) {
        refs = `Withdraw Note : ${item.withdrawNoteRef?.code}`
      } else if (item.deliveryNoteRef?.code) {
        refs = `Delivery Note : ${item.deliveryNoteRef?.code}`
      }

      let text = ''
      if (item.note && refs) {
        text = `${item.note} | from ${refs}`
      } else if (refs) {
        text = `from ${refs}`
      } else if (item.note) {
        text = item.note
      }

      return text
    },
    mapNowWarehouse (item) {
      if (this.isRef(item)) {
        const nowWarehouse = this.warehouse.find((r) => +r.id === +item.warehouse?.id)
        return nowWarehouse
      }
      return ''
    },
    mapNowWarehouseProp (item, prop = 'id') {
      if (this.isRef(item)) {
        const nowWarehouse = this.warehouse.find((r) => +r.id === +item.warehouse?.id)
        return nowWarehouse ? nowWarehouse[prop] : null
      }
      return null
    },
    mapOtherWarehouse (item) {
      if (this.isRef(item)) {
        if (this.to === 'DeliveryNote') {
          const otherWarehouse = this.warehouse.find((r) => r.id === item.withdrawNoteRef?.warehouseId)
          return otherWarehouse
        }
        if (this.to === 'WithdrawNote') {
          const otherWarehouse = this.warehouse.find((r) => r.id === item.deliveryNoteRef?.warehouseId)
          return otherWarehouse
        }
      }
      return null
    },
    mapOtherWarehouseProp (item, prop = 'id') {
      if (this.isRef(item)) {
        if (this.to === 'DeliveryNote') {
          const otherWarehouse = this.warehouse.find((r) => r.id === item.withdrawNoteRef?.warehouseId)
          return otherWarehouse ? otherWarehouse[prop] : null
        }
        if (this.to === 'WithdrawNote') {
          const otherWarehouse = this.warehouse.find((r) => r.id === item.deliveryNoteRef?.warehouseId)
          return otherWarehouse ? otherWarehouse[prop] : null
        }
      }
      return null
    },
    getLastId (id) {
      const idText = id ? id.toString() : '0'
      if (idText) {
        return idText[idText.length - 1]
      }
      return '0'
    },
    handleScroll (event) {
      this.$emit('scroll', event)
    },
    cancel (index) {
      this.$emit('cancel', index)
    },
    renew (index) {
      this.$emit('renew', index)
    }
  }
}
</script>

<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr.simple-table-loading td {
  padding: 0;
  height: 4px;
  border: 0 !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: auto;
  vertical-align: top;
  padding-top: 10px;
  padding-bottom: 10px;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spin {
  animation: spin 1s linear infinite;
}
.note-column {
  max-width: 650px;
}
</style>
